import {Injectable} from '@angular/core';
import {ApiService} from './api.service';

import {
  OfficialTeam,
  TeamAcl,
  TeamCategory,
  TeamPostAcl,
  TeamRelatedAccount,
  TeamStatus
} from '../model/resources/team';
import {HttpService} from "./http.service";

@Injectable({
  providedIn: 'root'
})
export class TeamApiService {

  constructor(private apiService: ApiService,
              private httpService: HttpService,
  ) {
  }

  async getTeam(teamId: number, hideLoading?: boolean): Promise<OfficialTeam | null> {
    try {
      return await this.apiService.get<OfficialTeam>(teamId, '', hideLoading);
    } catch (e) {
      return null;
    }
  }

  async updateTeam(teamId: number, updateTeamInput: UpdateTeamInput, hideLoading?: boolean, forceExecution?: boolean): Promise<OfficialTeam | null> {
    try {
      return await this.apiService.patch<OfficialTeam>(teamId, '', updateTeamInput, hideLoading, forceExecution);
    } catch (e) {
      return null;
    }
  }

  async patchTeamStatus(teamId: number, patchTeamStatusRequest: PatchTeamStatusRequest, hideLoading?: boolean, forceExecution?: boolean): Promise<boolean> {
    try {
      await this.apiService.patch(teamId, 'status', patchTeamStatusRequest, hideLoading, forceExecution);
      return true;
    } catch (e) {
      return false;
    }
  }

  async putTeamPhoto(teamId: number, photoType: TeamPhotoType, photo: File, hideLoading?: boolean): Promise<boolean> {
    const url = await this.getTeamPhotoUrl(teamId, photoType, hideLoading);
    if (!url) {
      return false;
    }
    try {
      await this.httpService.put(url, photo, hideLoading);
      return true;
    } catch (e) {
      return false;
    }
  }

  private async getTeamPhotoUrl(teamId: number, photoType: TeamPhotoType, hideLoading?: boolean): Promise<string | null> {
    try {
      const result = await this.apiService.get<{ url: string }>(teamId, `picture?photoType=${photoType}`, hideLoading);
      return result.url;
    } catch (e) {
      return null;
    }
  }

  async deleteTeamPhoto(teamId: number, photoType: TeamPhotoType, hideLoading?: boolean, forceExecution?: boolean): Promise<boolean> {
    try {
      await this.apiService.delete(teamId, `picture?photoType=${photoType}`, hideLoading, forceExecution);
      return true;
    } catch (e) {
      return false;
    }
  }

  async getRelatedAccounts(teamId: number, hideLoading?: boolean): Promise<TeamRelatedAccount[]> {
    try {
      return await this.apiService.get<TeamRelatedAccount[]>(teamId, `related-accounts`, hideLoading);
    } catch (e) {
      return [];
    }
  }

  async patchRelatedAccount(teamId: number, request: PatchOfficialTeamRelatedAccountRequest, hideLoading?: boolean): Promise<boolean> {
    try {
      await this.apiService.patch(teamId, `related-accounts`, request, hideLoading);
      return true;
    } catch (e) {
      return false;
    }
  }

  async deleteRelatedAccount(teamId: number, email: string, hideLoading?: boolean): Promise<boolean> {
    try {
      await this.apiService.delete(teamId, `related-accounts?email=${encodeURIComponent(email)}`, hideLoading);
      return true;
    } catch (e) {
      return false;
    }
  }

  async postRelatedAccount(teamId: number, account: TeamRelatedAccount, hideLoading?: boolean): Promise<boolean> {
    try {
      await this.apiService.post(teamId, `related-accounts`, {account}, hideLoading);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

}

export enum TeamPhotoType {
  IMAGE = 0,
  ICON = 1
}

export interface UpdateTeamInput {
  name: string;
  category: TeamCategory;
  area?: string;
  since?: number;
  description: string;
  webLink?: string;
  twitterLink?: string;
  facebookLink?: string;
  instagramLink?: string;
  youtubeLink?: string;
  aclTeam: TeamAcl; // チームの参加条件。招待制 = closedで、チーム検索の結果には出ない。誰でも参加可の場合は全ての項目がpublicとなる。
  aclPostFeed: TeamPostAcl; // 掲示板投稿権限
  aclPostTeamHistory: TeamPostAcl; // 活動実績投稿権限。基本的には0で、UI上は表示しない。 オフィシャルチームのみ設定を変更できる。
  aclCreateIntraEvent: TeamPostAcl; // チーム内イベント作成権限
  aclPostTeamCourse: TeamPostAcl; // ホームコース登録権限
  aclInviteMember: TeamPostAcl; // メンバー招待権限
  userId?: number; // 投稿者をTATTAユーザーとして扱う場合に指定
}

export interface PatchOfficialTeamRelatedAccountRequest {
  email: string; // キーとなる値。email自体の変更は許可しない
  name: string;
  denyNotifyFeed: boolean;
  denyNotifyImportant: boolean;
  userId?: number; // 指定しない場合は紐付けを取り消し
}

export interface PatchTeamStatusRequest {
  status: TeamStatus;
}
